.button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    width: 256px;
    cursor: pointer;
    border-radius: 0.5rem;
    display: inline-block;
    font-size: 16px;
}

.button:hover {
    background-color: #3c9140;
}
